import { Button, Card, Stack, Typography, Box, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Image from '#/components/shared/ui/Image';
import useLocales from '#/hooks/useLocales';
import Iconify from '#/components/shared/ui/Iconify';
import useAuth from '#/hooks/useAuth';

type Props = {
  icon: string;
  title: string;
  url: string;
  requested?: boolean;
  handleOpenModal?: () => void;
};

export default function EntryCard({
  icon,
  title,
  url,
  requested,
  handleOpenModal,
}: Props) {
  const { translate } = useLocales();

  const { user } = useAuth();

  const isConsultant = user?.partner?.role === 'consultant';

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Card
        sx={{
          p: 2,
          display: isConsultant && !requested ? 'none' : 'flex',
          flexDirection: 'column',
          height: '330px',
          opacity: !requested ? 0.5 : 1,
        }}
      >
        <Stack direction="column" spacing={2} sx={{ flex: 1 }}>
          <Image
            src={icon}
            sx={{
              width: 60,
              height: 60,
            }}
            alt={`${title} - ${title}`}
          />
          <Typography variant="h6">
            {String(
              translate(`global.consulting.dashCardInfos.${title}.title`)
            )}
          </Typography>
          <Typography variant="body2" sx={{ minHeight: '120px' }}>
            {String(
              translate(`global.consulting.dashCardInfos.${title}.description`)
            )}
          </Typography>
          <Link component={RouterLink} to={url}>
            <Button
              variant="contained"
              sx={{
                width: '150px',
              }}
            >
              <Typography>
                {String(
                  translate(`global.consulting.dashCardInfos.getStarted`)
                )}
              </Typography>
            </Button>
          </Link>
        </Stack>
      </Card>
      {!requested && !isConsultant && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: '150px',
            }}
            startIcon={<Iconify icon="eva:lock-fill" />}
            onClick={handleOpenModal}
          >
            <Typography>
              {String(translate(`global.consulting.dashCardInfos.unlock`))}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
